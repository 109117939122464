<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="panel panel-primary">
            <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">
                <template >
                    <b-table-column  v-slot="props" field="Contador" label="">
                        <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Amostra" :label="$t('RECEBIMENTOAMOSTRAS.AMOSTRA')">
                        <div class="columns">
                            <div class="column">
                                {{ props.row.amostraCodigoDeBarras }}
                                <div class="is-size-7 has-text-weight-bold">
                                    <span v-if="props.row.amostraRefMae && props.row.amostraTipo == 2">AL: </span>
                                    {{ props.row.amostraRefMaeCodBarras }}
                                </div>                
                            </div>
                            <div v-if="props.row.recebimentoConfirmado" v-show="config.utilizaValidacaoHimms" class="column">  
                                <b-select required :placeholder="$t('RECEBIMENTOAMOSTRAS.MOTIVO')" class="is-fullwidth" v-model="props.row.motivoRecebimento">
                                    <option v-for="item in motivosRecebimento" :key="item.id" :value="item.id">{{item.descricaoInterno}}</option>
                                </b-select>
                            </div>
                        </div>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Guia" :label="$t('RECEBIMENTOAMOSTRAS.GUIA')">
                        {{ props.row.guia }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="DataDeEmissao" :label="$t('RECEBIMENTOAMOSTRAS.DATAEMISSAO')">
                        {{ props.row.dataDeEmissao | data}}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Material" :label="$t('RECEBIMENTOAMOSTRAS.MATERIAL')">
                        {{ props.row.materialNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Conservante" :label="$t('RECEBIMENTOAMOSTRAS.CONSERVANTE')">
                        {{ props.row.conservanteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Setor" :label="$t('RECEBIMENTOAMOSTRAS.SETOR')">
                        {{ props.row.setorNome }}
                        <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Exames" :label="$t('RECEBIMENTOAMOSTRAS.EXAMES')">
                        <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                            {{item}}<span> </span>
                        </span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Acondicionamento" label=" ">
                        <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" :title="$t('RECEBIMENTOAMOSTRAS.ACONDICIONAMENTOCONGELADA')">C</span>
                        <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" :title="$t('RECEBIMENTOAMOSTRAS.ACONDICIONAMENTOREFRIGERADA')">R</span>
                        <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" :title="$t('RECEBIMENTOAMOSTRAS.ACONDICIONAMENTOAMBIENTE')">A</span>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Incidencia" label="Incidência">
                        <span class="tag pull-right is-success is-light button" @click="incidenciaManual(props.row);" v-if="props.row.incidencia == 'A'" :title="$t('RECEBIMENTOAMOSTRAS.ACEITARAMOSTRA')">A</span>
                        <span class="tag pull-right is-warning is-light " v-if="props.row.incidencia == 'R'" :title="$t('RECEBIMENTOAMOSTRAS.ACEITARAMOSTRARESTRICAO')">R</span>
                        <span class="tag pull-right is-danger is-light " v-if="props.row.incidencia == 'N'" :title="$t('RECEBIMENTOAMOSTRAS.REJEITARAMOSTRA')">N</span>
                    </b-table-column>
                </template>
            </b-table>
        </div>
        
        <div v-if="notificationSemAmostras && !notificationfiltroMatCon" class="notification is-warning">
            <h4>{{$t('RECEBIMENTOAMOSTRAS.FILTROSEMRESULTADO')}}</h4>
            {{$t('RECEBIMENTOAMOSTRAS.SOMENTEMATERIALENTREGUE')}}
        </div>

        <div v-if="notificationAjaxError" class="notification is-danger">
            {{notificationAjaxErrorMessage}}
        </div>

        <div v-if="notificationEnviandoAmostras" class="notification is-success">
           {{$t('RECEBIMENTOAMOSTRAS.AMOSTRARECEBIDA')}} 
        </div>


        <div class="box">
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(incluirAmostra)">
                                <label v-if="permiteDigitarAmostra"><strong>{{$t('RECEBIMENTOAMOSTRAS.CODIGOBARRAS')}}</strong></label>
                                <div class="field has-addons">
                                    <div class="control">                                 
                                        <input type="text" tabindex="1" v-if="permiteDigitarAmostra" v-model="amostraCodigoDeBarras" class="input" ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras"/>
                                        <input type="text" v-else v-model="amostraCodigoDeBarras" style="background-color: hsl(0, 0%, 96%);background-shade:hsl(0, 0%, 96%);color: hsl(0, 0%, 96%);border:0;" 
                                            class="input"  ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras" />
                                    </div>
                                    <div class="control">
                                        <b-button v-if="permiteDigitarAmostra"
                                                native-type="submit" type="is-success">
                                                {{$t('RECEBIMENTOAMOSTRAS.INCLUIR')}}
                                        </b-button>
                                        <b-button type="is-success is-hidden"
                                                native-type="submit">
                                                {{$t('RECEBIMENTOAMOSTRAS.INCLUIR')}}
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
                <div class="level-item">
                    <article class="level">
                        <div class="is-light">
                            <b-select tabindex="3" style="width:224px;" v-model="convenioId" :placeholder="$t('RECEBIMENTOAMOSTRAS.SELECIONACONVENIO')">
                                <template v-for="(item, index) in this.convenios">
                                    <option v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                            <b-select tabindex="4" style="width:224px;" :placeholder="$t('RECEBIMENTOAMOSTRAS.CONSERVANTES')" v-model="conservanteSelecionado">
                                <template v-for="item in conservantes">
                                    <option v-bind:key="item.id" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                            <b-select tabindex="5" style="width:224px;" :placeholder="$t('RECEBIMENTOAMOSTRAS.MATERIAIS')" v-model="materialSelecionado">
                                <template v-for="item in materiais">
                                    <option v-bind:key="item.id" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                    </article>
                </div>
                <div class="level-item">
                    <article class="level">
                        <div class="level-item">
                            <div v-show="permiteDigitarAmostra" class="is-light">
                                <label><strong>{{$t('RECEBIMENTOAMOSTRAS.REFERENCIA')}}</strong></label>
                                <div class="field has-addons">
                                    <div class="control">
                                        <input type="text" tabindex="6" v-model="amostraReferencia" class="input" ref="amostraReferencia"  id="amostraReferencia"/>
                                    </div>
                                    <div class="control">
                                        <a class="button is-success" @click="incluirReferencia();">
                                        {{$t('RECEBIMENTOAMOSTRAS.INCLUIR')}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="level-right">
                    <div class="is-light" v-if="permiteDigitarAmostra">
                        <label><strong>{{$t('RECEBIMENTOAMOSTRAS.LOTEAMOSTRAS')}}</strong></label>
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" tabindex="7" v-model="amostraLoteId" class="input" ref="amostraLoteId" id="amostraLoteId" />
                            </div>
                            <div class="control">
                                <a class="button is-success" @click="incluirLote();">
                                {{$t('RECEBIMENTOAMOSTRAS.INCLUIR')}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        
        <div class='box'>
            <div class="columns">
                <div class="column is-half" v-if="this.$route.params.referencia == null">
                    <div class="columns">
                        <div class="column is-4">
                            {{$t('RECEBIMENTOAMOSTRAS.FUNCIONARIORESPONSAVEL')}}
                            <b-select tabindex="8" v-model="model.funcionario.id">
                                <template v-for="(item, index) in this.funcionariosAtivos">
                                    <option v-if="model.funcionario.id == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-2" v-if="this.funcionariosAtivos.length > 0">
                            <br>
                            <div class="control">
                                <input type="text" tabindex="2" :placeholder="$t('RECEBIMENTOAMOSTRAS.IDENTIFICADOR')" v-model="funcionarioIdentificador" class="input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-half" v-else>
                </div>
                <div class="column">
                    <label><strong>{{$t('RECEBIMENTOAMOSTRAS.TEMPERATURA')}}</strong></label>
                    <input type="number" tabindex="9" v-model="temperatura" class="input" />
                    <span v-if="temLote && amostraLoteTemperatura">
                       <strong>{{$t('RECEBIMENTOAMOSTRAS.AMOSTRASLOTETEMPERATURA', {'0': amostraLoteTemperatura})}}</strong>
                    </span>
                </div>
                <div class="column">
                    <label><strong>{{$t('RECEBIMENTOAMOSTRAS.LATITUDE')}}</strong></label>
                    <input type="text" tabindex="10" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column">
                    <label><strong>{{$t('RECEBIMENTOAMOSTRAS.LONGITUDE')}}</strong></label>
                    <input type="text" tabindex="11" v-model="longitude" class="input" readonly/>
                </div>
            </div>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button tabindex="15" type="is-danger" icon-left="close-circle" @click="$router.back();">
                        {{$t('RECEBIMENTOAMOSTRAS.CANCELAR')}}
                    </b-button>
                    <b-button tabindex="14" v-if="this.$route.params.referencia == null" type="is-warning" @click="limparListagem();">
                        {{$t('RECEBIMENTOAMOSTRAS.LIMPAR')}}
                    </b-button>
                    <b-button tabindex="13" type="is-success" @click="imprimir();">
                        {{$t('RECEBIMENTOAMOSTRAS.IMPRIMIR')}}
                    </b-button>
                    <b-button v-if="amostras.filter(x => x.amostraTipo == 2).length > 0" type="is-success" @click="imprimir('aliquota');">
                        {{$t('CHECKPOINT.IMPRIMIRALIQUOTA')}}
                    </b-button>
                    <b-button tabindex="12" v-if="podeEnviar" type="is-success" icon-left="check-circle" @click="receberAmostras();">
                        {{$t('SISTEMA.ENVIAR')}}
                    </b-button>
                    
                    
                </p>
            </div>
        </nav>
    </section>
</template>
<script>
    import titlebar from '@/components/titlebar.vue'
    import { Model } from 'vue-property-decorator';
    import { mapGetters, mapState } from 'vuex'
    import modalRecebimentoIncidencia from '@/components/atendimento/modalRecebimentoIncidencia.vue'
    import modalRecebimentoIncidenciaManual from '@/components/atendimento/modalRecebimentoIncidenciaManual.vue'
    import modalRechecagemAmostra from '@/components/atendimento/modalRechecagemAmostra.vue'
    import modalAmostraIncluida from '@/components/atendimento/modalAmostraIncluida.vue'
    import notificacao from '@/assets/wrong-answer.mp3';
    import modalAmostraTempInadequada from '@/components/atendimento/modalAmostraTempInadequada.vue';

    export default {
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('RECEBIMENTOAMOSTRAS.RECEBIMENTO')
                ]
            },
            ...mapState([
                'unidadeId',
                'config'
            ])
        },
        watch: {
            funcionarioIdentificador: function(val){
                if(this.funcionariosAtivos.filter(func => func.identificador == val).length > 0){
                    this.model.funcionario.id = this.funcionariosAtivos.filter(func => func.identificador == val)[0].id
                }
            },
            amostraCodigoDeBarras: function (val) {
                //verifica se existe na lista
                if(this.amostras.filter(am => am.amostraCodigoDeBarras == val).length > 0){                    
                    this.amostras.filter(am => am.amostraCodigoDeBarras == val).forEach(item => {
                        item.amostraBipada = true;
                        if(this.checkedRows.filter(x => x.amostraCodigoDeBarras == val).length == 0)
                        {
                            this.checkedRows.push(item);
                        }
                    });
                }
            },
            checkedRows: function(val) {
                this.checkedRows.forEach(item => {
                    if(item.amostraBipada == null || !item.amostraBipada){
                        item.recebimentoConfirmado = true;
                    }
                });
            }
        },
        data() {
            return {
                model: {
                    funcionario: {
                        id: null
                    }
                },
                funcionariosAtivos: [],
                motivosRecebimento: [],
                convenios: [],
                amostras: [],
                todosSelecionado: false,
                funcionarioIdentificador: "",
                amostraCodigoDeBarras: "",
                amostraLoteId: "",
                amostraReferencia: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                notificationLoteAmostras: true,
                notificationSemAmostras: false,
                notificationfiltroMatCon: false,
                notificationAjaxError: false,
                notificationEnviandoAmostras: false,
                notificationAjaxErrorMessage: this.$t('RECEBIMENTOAMOSTRAS.ERROINFO'),
                convenioId: null,
                identificacaoDoFuncionario: null,
                identificacaoDoPaciente: null,
                podeEnviar: true,
                permiteDigitarAmostra: true,
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left',
                conservantes: [],
                materiais: [],
                conservanteSelecionado: null,
                materialSelecionado: null,
                temLote: false,
                amostraLoteTemperatura: null
            }
        },
        mounted(){
            this.$http.get('/api/atendimento/recebimentodeamostras')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.model = data;
                    }
                });
                
            this.$http.get('/api/search/FuncionariosAtivos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.funcionariosAtivos = data;
                    }
                });
                
            this.$http.get('/api/search/Convenios')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.convenios = data;
                    }
                });
                
            this.$http.get('/api/search/MotivosRecebimento').then(res => {
                    this.motivosRecebimento = res.data;
                });   

            this.$http.get('/api/search/Conservante')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.conservantes = data;
                    }
                }); 
                
            this.$http.get('/api/search/Material')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.materiais = data;
                }
            }); 

            if(this.$route.params.tipo){
                if(this.$route.params.tipo == 'coleta'){
                    //ocultar o box
                    this.permiteDigitarAmostra = false;
                    this.$refs.amostraCodigoDeBarras.focus();
                    
                    this.focusAmostra();
                    
                    if(this.$route.params.referencia){
                        //se tiver a referencia no parametro, obrigar o campo de identificação do funcionario
                        this.identificacaoDoPaciente = this.$route.params.referencia;
                        this.amostraReferencia = this.$route.params.referencia;
                        this.incluirReferencia();
                    }
                }
                else if(this.$route.params.tipo == 'impressao'){
                    //oculta o botão de enviar
                    this.podeEnviar = false;

                    if(this.$route.params.referencia){
                        //se tiver a referencia no parametro, obrigar o campo de identificação do funcionario
                        this.identificacaoDoPaciente = this.$route.params.referencia;
                        this.amostraReferencia = this.$route.params.referencia;
                        this.incluirReferencia();
                    }
                }
            }
            else {
                this.$refs.amostraCodigoDeBarras.focus();
            }

            if(this.$route.params.funcionario){
                this.identificacaoDoFuncionario = this.$route.params.funcionario;
            }

            if(this.$route.query.codigoDeBarras){
                this.amostraCodigoDeBarras = this.$route.query.codigoDeBarras;
                this.incluirAmostra();
            }
        },
         created() {
        this.$getLocation({})
            .then(coordinates => {
                this.latitude = coordinates.lat
                this.longitude = coordinates.lng
            });
        },    
        methods: {
            focusAmostra(){
                this.$refs.amostraCodigoDeBarras.focus();
            },
            limparListagem(){
                this.amostras = [];
                this.checkedRows = [];
            },
            selecionarTodos(){
                for (const i in this.amostras) {
                    this.amostras[i].confirmar = this.todosSelecionado;
                }
            },
            incluirAmostra(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;
                this.notificationfiltroMatCon = false
                let url = '';

                if(this.amostras.filter(am => am.amostraCodigoDeBarras == this.amostraCodigoDeBarras).length > 0){                    
                    if ((this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) && !this.temLote) {
                        this.exibirAlerta();
                        this.$buefy.dialog.alert({
                            title: this.$t('RECEBIMENTOAMOSTRAS.AMOSTRASELECIONADA'),
                            message: this.$t('RECEBIMENTOAMOSTRAS.MENSAGEMAMOSTRA') + this.amostraCodigoDeBarras +  this.$t('RECEBIMENTOAMOSTRAS.AMOSTRAINCLUIDA'),
                            type: 'is-warning',
                            hasIcon: true,
                            onConfirm: () => this.$refs.amostraCodigoDeBarras.focus(),
                        });
                    }

                    this.amostraCodigoDeBarras = null;
                }

                if(this.conservanteSelecionado != null){
                    url += '&conservanteId=' + this.conservanteSelecionado;
                }

                if(this.materialSelecionado != null){
                    url += '&materialId=' + this.materialSelecionado;
                }
                if (this.amostraCodigoDeBarras != null && this.amostraCodigoDeBarras != "") {
                    if(this.temLote == true){
                        const amostraLote = this.amostras.find(a => a.amostraLote != null);

                        if(amostraLote != null){
                            this.$buefy.modal.open({
                                parent: this,
                                component: modalAmostraIncluida,
                                props: {
                                    amostraLote: amostraLote,
                                    amostraCodigoDeBarras: this.amostraCodigoDeBarras,
                                    url: url
                                },
                                events: {
                                    recebimentoDeAmostras: this.recebimentoDeAmostras
                                },
                                hasModalCard: true,
                                trapFocus: true,
                            })
                        }
                    }
                    else{
                        this.recebimentoDeAmostras(this.amostraCodigoDeBarras, url);                        
                    }
                }
            },
            exibirAlerta(){
                const audio = new Audio(notificacao)
                audio.play()
            },
            recebimentoDeAmostras(amostraCodigoDeBarras, url){
                this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?amostra=' + amostraCodigoDeBarras + url)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null && data.length != 0) {
                                for (const i in data) {         
    
                                    if (this.config.utilizaValidacaoHimms != null && this.config.utilizaValidacaoHimms == true
                                        && (data[i].guia == null || data[i].guia.indexOf(this.identificacaoDoPaciente) != 0)) {
                                        this.$buefy.toast.open({
                                            duration: 5000,
                                            message: this.$t('RECEBIMENTOAMOSTRAS.AMOSTRANAOPERTENCE'),
                                            type: 'is-warning',
                                            queue: false
                                        });
                                        continue;
                                    }
                                    else if (this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0) {
                                        
                                        if(data[i].temIncidencia){
                                            this.incidencia(data[i]);
                                        }
                                        else {
                                            this.amostras.push(data[i]);
                                            if (this.temLote) {
                                                this.checkedRows.push(data[i]);
                                            } else if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                                                this.checkedRows.push(data[i]);
                                            }
                                        }
                                    }
                                }
                            } else {
                                const materialNome = this.materialSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.MATERIAL')}</b>` : "";
                                const conservanteNome = this.conservanteSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.CONSERVANTE')}</b>` : "";
                                let materialConservante = '';

                                if (materialNome && conservanteNome) {
                                    materialConservante = conservanteNome + ', ' + materialNome;
                                } else if (materialNome) {
                                    materialConservante = materialNome;
                                } else if (conservanteNome) {
                                    materialConservante = conservanteNome;
                                }

                                if ((this.conservanteSelecionado != null && data.conservanteId != this.conservanteSelecionado) ||
                                    (this.materialSelecionado != null && data.materialId != this.materialSelecionado)) {
                                    this.notificationfiltroMatCon = true;
                                        this.$buefy.toast.open({
                                            message: this.$t('RECEBIMENTOAMOSTRAS.ALERTAFILTROMATCON', {'0': materialConservante}),
                                            type: 'is-warning',
                                            duration: 3000 
                                        });
                                }
                                this.notificationSemAmostras = true;
                            }
                            this.$refs.amostraCodigoDeBarras.focus();
                        })
                        .catch((error) => {
                            this.notificationAjaxError = true;
                        }); 
                this.amostraCodigoDeBarras = "";
            },
            incluirLote(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;

                let url = '';
                if(this.convenioId != null){
                    url += '&convenioId=' + this.convenioId;
                }

                if(this.conservanteSelecionado != null){
                    url += '&conservanteId=' + this.conservanteSelecionado;
                }

                if(this.materialSelecionado != null){
                    url += '&materialId=' + this.materialSelecionado;
                }

                this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?amostraLoteId=' + this.amostraLoteId + url)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        this.amostraLoteTemperatura = data[0].amostraLoteTemperatura;
                        for (const i in data) {
                            if(this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0){

                                if(data[i].temIncidencia){
                                    this.incidencia(data[i]);
                                }else{                                                                
                                    this.amostras.push(data[i]);
                                }   
                                this.temLote = true;
                            }
                        }
                    } else {
                        const convenioNome = this.convenioId ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.CONVENIO')}</b>` : "";
                        const materialNome = this.materialSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.MATERIAL')}</b>` : "";
                        const conservanteNome = this.conservanteSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.CONSERVANTE')}</b>` : "";
                        let materialConservanteConvenio = '';

                        if (materialNome) {
                            materialConservanteConvenio += materialNome + '\n';
                        } 
                        if (conservanteNome) {
                            materialConservanteConvenio += conservanteNome + '\n';
                        } 
                        if (convenioNome) {
                            materialConservanteConvenio += convenioNome + '\n';
                        }
                        
                        if ((this.conservanteSelecionado != null && data.conservanteId != this.conservanteSelecionado) ||
                            (this.materialSelecionado != null && data.materialId != this.materialSelecionado ) ||
                            (this.convenioId != null && data.convenioId != this.convenioId)) {
                            this.notificationfiltroMatCon = true;
                            this.$buefy.toast.open({
                                message: this.$t('RECEBIMENTOAMOSTRAS.ALERTAFILTROMATCON', {'0': materialConservanteConvenio}),
                                type: 'is-warning',
                                duration: 3000 
                            });
                        }
                        this.notificationSemAmostras = true;
                        this.convenioId = null;
                        this.conservanteSelecionado = null;
                        this.materialSelecionado = null;
                    }
                    this.$refs.amostraLoteId.focus();
                })
                .catch((error) => {
                    this.notificationAjaxError = true;
                }); 

                this.amostraLoteId = "";
            },
            incluirReferencia(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;

                let url = '';
                if(this.convenioId != null){
                    url += '&convenioId=' + this.convenioId;
                }

                if(this.conservanteSelecionado != null){
                    url += '&conservanteId=' + this.conservanteSelecionado;
                }

                if(this.materialSelecionado != null){
                    url += '&materialId=' + this.materialSelecionado;
                }

                if (this.config.utilizaValidacaoHimms) {
                    this.identificacaoDoPaciente = this.amostraReferencia;
                    this.identificacaoDoFuncionario = this.model.funcionario.nome;
                }

                this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?referencia=' + this.amostraReferencia + url)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0){

                                if(data[i].temIncidencia){
                                    this.incidencia(data[i]);
                                }else{   
                                    this.amostras.push(data[i]);
                                    if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                                        this.checkedRows.push(data[i]);
                                    }                                    
                                }    
                            }
                        }
                    } else {
                        const materialNome = this.materialSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.MATERIAL')}</b>` : "";
                        const conservanteNome = this.conservanteSelecionado ? `<b>${this.$t('RECEBIMENTOAMOSTRAS.CONSERVANTE')}</b>` : "";
                        let materialConservante = '';

                        if (materialNome && conservanteNome) {
                            materialConservante = conservanteNome + ', ' + materialNome;
                        } else if (materialNome) {
                            materialConservante = materialNome;
                        } else if (conservanteNome) {
                            materialConservante = conservanteNome;
                        }

                        if ((this.conservanteSelecionado != null && data.conservanteId != this.conservanteSelecionado) ||
                            (this.materialSelecionado != null && data.materialId != this.materialSelecionado)) {
                            this.notificationfiltroMatCon = true;
                            this.$buefy.toast.open({
                                message: this.$t('RECEBIMENTOAMOSTRAS.ALERTAFILTROMATCON', {'0': materialConservante}),
                                type: 'is-warning',
                                duration: 3000 
                            });
                        }
                        this.notificationSemAmostras = true;
                    }
                    this.$refs.amostraReferencia.focus();
                })
                .catch((error) => {
                    this.notificationAjaxError = true;
                }); 

                this.amostraReferencia = "";
            },
            imprimir(tipo){
                const modelo = window.localStorage.getItem("etiquetaAtual");

                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    if (tipo == 'aliquota') {
                        this.$notificacaoHub.invoke("EtiquetaAmostra",
                            this.amostras.filter(x => x.amostraTipo != null && (x.amostraTipo == 2))?.map(item => {
                                return item.amostraId;
                            })
                            , modelo,false);
                    } 
                    else {
                        this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.checkedRows?.map(item => {
                            return item.amostraId;
                        })
                        , modelo,false);
                    }
                }
                else {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.amostras?.map(item => {
                            return item.amostraId;
                        })
                    , modelo,false);
                }

                this.$refs.amostraCodigoDeBarras.focus();
            },
            receberAmostras(){

                this.notificationAjaxError = false;

                let erroSemMotivo = false;
                this.checkedRows.forEach(amostra => {
                    if (amostra.recebimentoConfirmado && this.config.utilizaValidacaoHimms) {
                        if(amostra.motivoRecebimento == null){
                            this.notificationAjaxErrorMessage = this.$t('RECEBIMENTOAMOSTRAS.MOTIVOOBRIGATORIO');
                            this.notificationAjaxError = true;
                            erroSemMotivo = true;
                        }
                    }
                    amostra.confirmar = true;
                });

                if(erroSemMotivo){
                    return;
                }

                const recebimentoDeAmostras = {
                    amostras: this.checkedRows, 
                    funcionarioId: this.model.funcionario.id, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude
                };

                if(recebimentoDeAmostras.temperatura !== ""){
                    const amostrasParaValidar = recebimentoDeAmostras.amostras.filter(a => this.isInvalidAmostra(a));

                    if(amostrasParaValidar && amostrasParaValidar.length){
                        this.$buefy.modal.open({
                            parent: this,
                            component: modalAmostraTempInadequada,
                            props: {
                                amostras: amostrasParaValidar,
                                temperaturaInformada: parseFloat(recebimentoDeAmostras.temperatura.replace(",", "."))
                            },
                            events: {
                                atualizarAmostras: this.atualizarAmostras
                            },
                            hasModalCard: true,
                            trapFocus: true
                        })
                        return;
                    }
                }

                if (this.$route.params.referencia || this.config.utilizaValidacaoHimms)
                {
                    if(this.identificacaoDoFuncionario){
                        recebimentoDeAmostras.identificacaoDoFuncionario = this.identificacaoDoFuncionario;
                    }
                    else {                        
                        this.notificationAjaxErrorMessage = this.$t('RECEBIMENTOAMOSTRAS.IDENTIFICACAO');
                        this.notificationAjaxError = true;
                        return;
                    }

                    if(this.identificacaoDoPaciente){
                        recebimentoDeAmostras.identificacaoDoPaciente = this.identificacaoDoPaciente;
                    }
                    else {                        
                        this.notificationAjaxErrorMessage = this.$t('RECEBIMENTOAMOSTRAS.IDENTIFICACAOPACIENTE');
                        this.notificationAjaxError = true;
                        return;
                    }
                }
                
                if(this.checkedRows.length != this.amostras.length){
                    const amostraNaoChecada = this.amostras.filter(amostra => !this.checkedRows.includes(amostra));
                    
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalRechecagemAmostra,
                        props: {
                            amostras: this.amostras,
                            amostraNaoChecada: amostraNaoChecada,
                            recebimentoDeAmostras: recebimentoDeAmostras
                        },
                        events: {
                            receberAmostrasApi: this.receberAmostrasApi,
                            atualizarAmostras: this.atualizarAmostras
                        },
                        hasModalCard: true,
                        trapFocus: true,
                    })
                }
                else{
                    this.receberAmostrasApi(recebimentoDeAmostras)
                }
            },
            receberAmostrasApi(recebimentoDeAmostras){
                this.$refs.amostraCodigoDeBarras.focus();
                    this.notificationEnviandoAmostras = true;
                    this.$http.post(`/api/atendimento/ReceberAmostras`, recebimentoDeAmostras )
                    .then(({ data }) => {
                        this.notificationEnviandoAmostras = false;
                    })
                    .catch((error) => {
                        this.notificationEnviandoAmostras = false;
                        this.notificationAjaxErrorMessage = error.body;
                        this.notificationAjaxError = true;
                    })
                    .finally(() => {
                        this.$refs.amostraCodigoDeBarras.focus();
                    }) 
                    
                this.amostras = [];
                this.checkedRows = [];
                this.amostraLoteTemperatura = null;
                this.temLote = false;
            },
            atualizarAmostras(am) {
                am.forEach(a => {
                    const index = this.amostras.findIndex(item => item.amostraId === a.amostraId);
                    if(index !== -1){
                        this.amostras[index] = a
                    }
                });
            },
            confirmarIncidencia(amostra){
                this.amostras.push(amostra);
                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    this.checkedRows.push(amostra);
                }
            },
            atualizarIncidencia(amostraIndicendia){
                this.amostras.filter(amostra => amostra.amostraCodigoDeBarras == amostraIndicendia.amostraCodigoDeBarras).forEach(item => {               
                    
                    item.motivoIncidencia = amostraIndicendia.motivoIncidencia;
                    item.incidencia = amostraIndicendia.incidencia;

                });
            },            
            incidencia(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidencia,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        confirmarIncidencia: this.confirmarIncidencia
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            incidenciaManual(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidenciaManual,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        atualizarIncidencia: this.atualizarIncidencia
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            isInvalidAmostra(amostra) {
                const temperaturaInformada = parseFloat(this.temperatura.replace(",", "."));

                if(amostra.incidencia == 'R' == amostra.incidencia == 'N')
                    return false;

                if(amostra.amostraAcondicionamento && amostra.motivoIncidencia == 0 && 
                   (amostra.incidencia != 'R' || amostra.incidencia != 'N') &&
                   (amostra.estabilidadeAmbienteDe != null || amostra.estabilidadeAmbienteAte != null ||
                   amostra.estabilidadeCongeladoDe != null || amostra.estabilidadeCongeladoAte != null ||
                   amostra.estabilidadeRefrigeradoDe != null || amostra.estabilidadeRefrigeradoAte != null)){
                    
                    if(amostra.amostraAcondicionamento == 1){
                        // maior que
                        if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeAmbienteDe == null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                        // entre
                        else if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe && temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 2){
                        // maior que
                        if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeRefrigeradoDe == null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe && temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 3){
                        // maior que
                        if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeCongeladoDe == null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe && temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                    }
                }
            },
        }
    }
</script>